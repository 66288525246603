<template>
  <div class="hello">
    <b-img alt="Vue logo" src="@/assets/logo.png" />
    <h1>{{ msg }}</h1>
    <h1>File Picker</h1>
    <FilePicker />
    <RichTextEditorExamples class="mt-5" />
  </div>
</template>

<script>
import { BImg } from 'bootstrap-vue';
import FilePicker from '../views/file/components/FilePicker.vue';
import RichTextEditorExamples from './RichTextEditorExamples.vue';

export default {
  name: 'HelloWorld',
  components: { FilePicker, RichTextEditorExamples, BImg },
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">

</style>
